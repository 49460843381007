<template>
	<div class="contain flex">
		<div class="page-content">
			<div class="bm-wapper">
				<div class="br-bar">
					<step-bar :active="0"></step-bar>
				</div>
				<div class="bm-content">
					<title-bar :titles="['招考报名','网上报名']"></title-bar>
					<div style="width:100%;margin:20px auto 0;">
						<span style="color: red;">个人报名情况，请前往"个人中心=>招考报名"进行查看。</span>
						<el-input style="width: 320px;" placeholder="请输入您需要搜索的关键字" size="mini"> <i slot="suffix"
							class="el-input__icon el-icon-search"></i></el-input>
					</div>
					<div class="zk-list-item" v-for="item in newslist" :key="item.id">
						<div class="zk-right">
							<h1>{{ utils.timeFormat(item.createTime,4) }}</h1>
							<span>{{ utils.timeFormat(item.createTime,3) }}</span>
						</div>
						<div class="zk-center">
							<div style="line-height: 25px; font-size: 18px;">
								{{ item.name }}
							</div>
							<div style="line-height: 40px; font-size: 14px;">
								{{ item.timeState }}
							</div>
							
						</div>
						<div class="zk-left">
							<el-button class="btn" @click="gozkInfoPage(item.id)">报名</el-button>
						</div>
					</div>
					<el-empty v-if="newslist.length < 1" :image-size="200"></el-empty>
					<div style="text-align: center; margin-top: 20px">
						<el-pagination hide-on-single-page @size-change="handleSizeChange"
							@current-change="handleCurrentChange" :current-page="selfrom.pageNumber"
							:page-sizes="[20, 50, 100, 150, 200]" :page-size="selfrom.pageSize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import StepBar from './stepbar.vue';
	import TitleBar from './titlebar.vue';
	import utils from "@/assets/js/utils";
	import {
		isnull
	} from "@/assets/js/index";
	import {
		ExamPageList,
		ExamSignUpList,
		test
	} from "@/api/user";
	export default {
		components: {
			StepBar,
			TitleBar
		},
		data() {
			return {
				selfrom: {
					pageNumber: 1,
					pageSize: 20,
					KM: "",
					cid: ''
				},
				total: 0,
				newslist: [],
			}
		},
		created() {
			this.getPage()
			this.test()
		},
		methods: {
			test() {
				console.log(123)
				test({

				}).then((res) => {
					console.log(res)
				})
				
			},
			gozkInfoPage(id) {
				this.$router.push("zhaokaoInfo?id=" + id)
			},
			checkPrintEndTime(printEndTime) {
				// 通过打印截止时间判断是否已经招考，从而判断是否显示
				var dateNow = new Date();
				var dateEnd = new Date(printEndTime.replace(/-/g, "/"));
				var dateDiff = dateEnd.getTime() - dateNow.getTime();
				if (dateDiff < 0) {
					return false;
				}
				return true;
			},
			// 获取招考时间状态
			getTimeState(element) {
				if (utils.isDuringTime(element.enrollStartTime, element.enrollEndTime) == 1) {
					return "报名阶段(" + element.enrollStartTime + " - " + element.enrollEndTime + ")"
				} else if (utils.isDuringTime(element.trialStartTime, element.trialEndTime) == 1) {
					return "初审阶段(" + element.trialStartTime + " - " + element.trialEndTime + ")"
				} else if (utils.isDuringTime(element.reviewStartTime, element.reviewEndTime) == 1) {
					return "复审阶段(" + element.reviewStartTime + " - " + element.reviewEndTime + ")"
				} else if (utils.isDuringTime(element.payStartTime, element.payEndTime) == 1) {
					return "缴费阶段(" + element.payStartTime + " - " + element.payEndTime + ")"
				} else if (utils.isDuringTime(element.printStartTime, element.printEndTime) == 1) {
					return "打印准考证阶段(" + element.printStartTime + " - " + element.printEndTime + ")"
				} else if (utils.isDuringTime(element.printStartTime, element.printEndTime) == 3) {
					return "已结束"
				} else {
					return "查询阶段"
				}
			},
			getPage() {
				var _this = this;
				ExamPageList(this.selfrom).then((res) => {
					if (res.success) {
						// _this.newslist = res.data.rows;
						// 在打印截止时间之前的话可以显示
						res.data.rows.forEach((element) => {
							// 招考时间状态
							element.timeState = this.getTimeState(element);
							// 是否展示
							var isShow = this.checkPrintEndTime(element.printEndTime);
							if (isShow) {
								_this.newslist.push(element);
							}
						})
						console.log(_this.newslist)
						_this.total = res.data.total;
					}
				});
			},
			handleSizeChange(val) {
				this.selfrom.pageNumber = 1;
				this.selfrom.pageSize = val;
				this.getPage();
			},
			handleCurrentChange(val) {
				this.selfrom.pageNumber = val;
				this.getPage();
			},
			search() {
				this.selfrom.pageNumber = 1;
				this.getPage();
			},
		}
	}
</script>
<style lang="scss" scoped>
	.contain {
		background-color: #fff;
		min-height: calc(100vh - 125px);
	}

	.page-content {
		width: 1200px;
		margin: 0 auto;
		padding: 20px 0;
	}


	.bm-wapper {
		padding: 20px 0;
		display: flex;
	}

	.bm-bar {
		width: 220px;
		display: flex;
	}

	.bm-content {
		flex: 1;
		padding: 0 48px;
	}

	.zk-list-item {
		margin-top: 20px;
		border: 1px solid #f8f8f8;
		padding: 12px;
		cursor: pointer;
		display: flex;
		line-height: 60px;

		.zk-left {
			flex: 1;
			font-size: 14px;
			color: #333333;

			.btn {
				background-color: #098FF0;
				width: 80px;
				height: 30px;
				line-height: 30px;
				color: #fff;
				padding: 0;
			}
		}

		.zk-center {
			flex: 6;
			font-size: 16px;
			padding: 0 10px;
		}

		.zk-right {
			font-size: 13px;
			color: #898989;
			width: 60px;
			height: 60px;
			background-color: #098FF0;
			text-align: center;
			color: #fff;
			display: block;

			h1 {
				line-height: 40px;
				display: block;
				padding: 0;
				margin: 0;
			}

			span {
				line-height: 20px;
				display: block;
			}
		}
	}

	.zk-pagination {
		text-align: right;
		padding: 30px 0;
		padding-bottom: 0px;
	}
</style>