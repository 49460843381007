<template>
	<div class="title-bar">首页 {{bar}}</div>
</template>
<script>
	export default {
		props: {
			titles: []
		},
		data() {
			return {
				bar: ""
			}
		},
		created() {
			if (this.titles.length > 0) {
				this.titles.forEach((element) => {
					this.bar += " > " + element;
				});
			}
		}
	}
</script>
<style lang="scss" scoped>
	.title-bar {
		color: #898989;
		font-size: 14px;
		border-bottom: 1px solid #eee;
		line-height: 45px;
	}
</style>