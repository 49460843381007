<template>
	<div class="bar-wapper">
		<div class="bar-title">招考报名</div>
		<div class="bar-list">
			<ul>
				<li v-for="(item,index) in btnlist" class="" @click="go(item.url)">
					<div class="icon">

					</div>
					<div :class="[index==active?'active':'','name']">{{item.name}}</div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			active: 0
		},
		data() {
			return {
				btnlist: [{
						url: 'zhaokaobm',
						name: '网上报名'
					},
					{
						url: 'zhaokaoPay',
						name: '缴费'
					},
					{
						url: 'zhaokaoTicket',
						name: '准考证打印'
					},
					// {
					// 	type: 3,
					// 	name: '成绩查询'
					// },
				],
			}
		},
		methods: {
			goto(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},
			go(url) {
				this.goto( url, {}, 1);
			}
		},
	}
</script>
<style lang="scss" scoped>
	.bar-wapper {
		width: 220px;
		background: #f8f8f8;
		min-height: 80vh;
	}

	.bar-title {
		font-size: 36px;
		font-weight: bold;
		line-height: 117px;
		text-align: center;
		color: #fff;
		background-color: #098FF0;
	}

	.bar-list {
		padding: 20px 17px;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				font-size: 16px;
				background-color: #fff;
				line-height: 62px;
				margin-bottom: 20px;
				display: flex;
				cursor: pointer;

				.icon {
					width: 30%;
				}

				.name {
					color: #101010
				}

				.active {
					color: #098FF0
				}
			}

			li:hover {
				background-color: #098FF0;

				.name {
					color: #fff
				}

				.active {
					color: #fff
				}
			}
		}
	}
</style>